import React, { useCallback, useEffect, useMemo, useReducer, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Table from "@components/Table";
import { accumulateOptimalLocation, headings } from "@screens/Network/Pool/Servers/utils";
import * as poolStyle from "@screens/Network/Pool/style.scss";
import { If } from "@components/If";
import { TableDataType } from "@components/Table/types";
import RootState from "@state/interfaces";
import { initialStateModal, reducerModal } from "@components/Modal/reducer";
import { IPoolGroup, IRequestSelector } from "@screens/Network/Pool/Servers/types";
import { EModals } from "@components/Modal/types";
import * as buttonStyle from "@components/Button/style.scss";
import { editActiveProject } from "@state/activeProject";
import { OptimalLocationModal } from "@screens/Network/Pool/Servers/OptimalLocationModal";
import { Modal } from "@components/Modal";
import { changePool } from "@state/pools";
import { defaultPool } from "@screens/Network/Pool/utils";
import { IconPlus } from "@common/icons";
import { ConfigType } from "@screens/Projects/Projects.types";

const initLocation: IPoolGroup = {
    description: "",
    name: "",
    priority: 0,
    request_selector: {
        private_group: null,
        user_country: { in: [] },
        user_state: { in: [] },
    },
    server_selector: { server_state: { in: [] } },
};

const initialState = initialStateModal(initLocation);
const reducerLocation = reducerModal(initialState);

const OptimalLocation = () => {
    const dispatch = useDispatch();
    const [body, setBody] = useState<TableDataType[]>([]);
    const activeProject = useSelector((state: RootState) => state.activeProject);
    const pool = useSelector((state: RootState) => state.pools.pool ?? defaultPool);
    const [modals, dispatchModal] = useReducer(reducerLocation, initialState);
    const canWrite = useMemo(
        () =>
            pool.permissions.some(
                (permission: any) => permission.subject === activeProject.publickey && permission.can_write,
            ),
        [activeProject.publickey, pool.permissions],
    );
    const isPrivate = useMemo(
        () =>
            pool.permissions.some(
                (permission: any) => permission.subject === activeProject.publickey && permission.can_list,
            ),
        [activeProject.publickey, pool.permissions],
    );

    const changeGroupStatus = useCallback(
        (requestSelector: IRequestSelector) => {
            const config = activeProject.config as ConfigType;

            if (!config.private_groups) config.private_groups = [];
            const existGroup = config.private_groups.indexOf(requestSelector.private_group) !== -1;
            if (!existGroup) {
                config.private_groups.push(requestSelector.private_group);
            } else {
                config.private_groups.splice(config.private_groups.indexOf(requestSelector.private_group), 1);
            }

            dispatch(
                editActiveProject({
                    ...activeProject,
                    config,
                }),
            );
        },
        [activeProject, dispatch],
    );

    const showEditModal = (poolGroup: IPoolGroup) => {
        dispatchModal({
            type: EModals.EditModal,
            payload: poolGroup,
        });
    };

    const showDeleteModal = (poolGroup: IPoolGroup) => {
        dispatchModal({
            type: EModals.DeleteModal,
            payload: poolGroup,
        });
    };

    const closeModal = () => {
        dispatchModal({
            type: EModals.CloseModals,
            payload: initLocation,
        });
    };

    useEffect(() => {
        if (!pool) return;
        const { groups = [] } = pool;
        const { private_groups = [] } = activeProject.config as ConfigType;
        const sortedGroups = [...groups].sort((a, b) => {
            if (a.priority > b.priority) return -1;
            if (a.priority < b.priority) return 1;
            return 0;
        });

        const content = accumulateOptimalLocation(
            sortedGroups,
            private_groups,
            changeGroupStatus,
            canWrite,
            isPrivate,
            showEditModal,
            showDeleteModal,
        );

        setBody(content);
    }, [activeProject.config, canWrite, changeGroupStatus, isPrivate, pool]);

    const deleteLocation = () => {
        dispatch(
            changePool({
                pool: pool.name,
                body: {
                    del_groups: [modals.item.name],
                },
            }),
        );
        closeModal();
    };

    const addPoolGroup = (poolGroup: IPoolGroup) => {
        dispatch(
            changePool({
                pool: pool.name,
                body: {
                    add_groups: [poolGroup],
                },
            }),
        );
    };

    const editPoolGroup = (poolGroup: IPoolGroup) => {
        dispatch(
            changePool({
                pool: pool.name,
                body: {
                    add_groups: [poolGroup],
                    del_groups: [poolGroup.name],
                },
            }),
        );
    };

    return (
        <>
            <Table tableData={body} headings={headings} title="Optimal Location" emptyMessage={window.locales.noGroups}>
                <If condition={canWrite}>
                    <div className={poolStyle.extraButtons}>
                        <button
                            className={buttonStyle.buttonAdd}
                            type="submit"
                            onClick={() =>
                                dispatchModal({
                                    type: EModals.CreateModal,
                                    payload: initLocation,
                                })
                            }
                        >
                            <IconPlus theme="blue" />
                            {window.locales.add}
                        </button>
                    </div>
                </If>
            </Table>

            {/* Modal add pool group */}
            <OptimalLocationModal
                title={window.locales.addRule}
                isOpen={modals.createModal}
                closeModal={closeModal}
                action={addPoolGroup}
                poolGroup={modals.item}
            />

            {/* Modal edit pool group */}
            <OptimalLocationModal
                title={window.locales.editRule}
                isOpen={modals.editModal}
                closeModal={closeModal}
                action={editPoolGroup}
                poolGroup={modals.item}
            />

            {/* Modal delete pool group */}
            <Modal
                isNegative
                isOpen={modals.deleteModal}
                onClose={closeModal}
                title={window.locales.deletePoolGroup}
                confirm={{
                    label: window.locales.delete,
                    onConfirm: deleteLocation,
                }}
            >
                Do you want to delete the pool location?
            </Modal>
        </>
    );
};

export default OptimalLocation;
