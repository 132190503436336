import { ReactElement, ReactNode } from "react";

interface TableObjectProps {
    sortColumn?: number | string;
    component?: ReactElement | string;
    tooltip?: {
        title: string;
        content: string;
    };
}

type TableDataType = {
    [key: string]: string | TableObjectProps | number | (() => void);
};

type TSortOptions = { heading: string; type: ESort };

interface SortObject {
    string: (a: string, b: string) => any;
    number: (a: number, b: number) => any;
}

interface TableProps {
    tableData: TableDataType[];
    headings: { isSort: boolean; heading: string }[];
    title: string;
    emptyMessage: string;
    children?: ReactNode;
}

interface TableBodyProps {
    tableData: TableDataType[];
    isReduce: boolean;
}

interface TableHeaderProps {
    headings: { isSort: boolean; heading: string }[];
    sort: (heading: string, type: ESort) => void;
    sortOptions: TSortOptions;
}

interface IDataItemProps {
    date: string;
    time: string;
}

interface IconAProps {
    rotate?: boolean;
    sorted?: boolean;
    ref: any;
}

interface IColumn {
    isSort: boolean;
    heading: string;
    sortOptions: TSortOptions;
    sort: (name: string, type: ESort) => void;
}

enum ESort {
    Up = "up",
    Down = "down",
}

export type {
    IconAProps,
    IDataItemProps,
    TableHeaderProps,
    TableBodyProps,
    TableProps,
    SortObject,
    TableDataType,
    TableObjectProps,
    IColumn,
};

export { ESort };
